// from _variables.scss
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f6f6f6;
$gray-310: #f7f7f7;
$gray-320: #f2f2f2;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$danger: #f46a6a;
$warning: #f1da59;
$warning-light: #efdfc4;

// react-toastify customization
.Toastify__toast {
  border-radius: 0;
}

.toast-normal {
  background-color: $gray-800;
  color: $gray-200;
}

.toast-error {
  background-color: $danger;
  color: white;
}

.Toastify__close-button {
  color: $gray-200;
}

.Toastify__progress-bar {
  visibility: hidden;
}

// CKEditor 5 customization
.ck-editor__editable {
  min-height: 200px;
}

.ck-editor__nested-editable {
  min-height: 0;
}

// react-datepicker customization
.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker {
  border-radius: 0;
  box-shadow: 0 0 4px $gray-400;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-datepicker-wrapper.has-max-width {
  max-width: 540px;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
  color: $gray-700;
}

.react-datepicker__day-name {
  font-size: 0.75rem;
}

.react-datepicker__month {
  margin-top: 0;
}

.react-datepicker__day {
  color: $gray-700;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  &:hover {
    background-color: $gray-320;
    border-radius: 0;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $gray-800 !important;
  border-radius: 0 !important;
  color: $gray-200 !important;

  &:hover {
    background-color: $gray-800 !important;
    border-radius: 0 !important;
    color: $gray-200 !important;
  }
}

.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;

  &:hover {
    background-color: none;
    color: black;
  }
}

.react-datepicker__day-name,
.react-datepicker__day--outside-month {
  color: $gray-500;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  text-decoration: underline;
}

.react-datepicker__input-time-container {
  margin: 0;
  border-top: 1px solid $gray-400;
}

.react-datepicker-time__input-container {
  width: 100%;
}

.react-datepicker-time__input {
  width: 100%;
  margin-left: 0 !important;
}

.react-datepicker__day--disabled {
  color: $gray-500;
  cursor: default;

  &:hover {
    background-color: white;
  }
}

.date-picker-time-input {
  max-width: 94px;
  border: none;
  text-align: center;
}

.react-datepicker__close-icon::after {
  background: none;
  border-radius: 0;
  padding: 0 8px 0 0;
  font-size: 22px;
  color: #cccccc;
}

// react-signature-canvas
.sigCanvas {
  border: 1px solid $gray-400;
  cursor: url("../images/pen-solid-sm.svg"), auto;
}

// react-d3-tree
.react-tree-node-container {
  button {
    width: 100%;
    text-align: center;
    border: none;
    background: white;
  }
}

.react-tree-node__root .react-tree-node-box,
.react-tree-node__branch .react-tree-node-box,
.react-tree-node__leaf .react-tree-node-box {
  background: white;
  border: 1px solid $gray-600;
  cursor: grab;

  p:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &.focused {
    background: $warning-light;
  }
}

// react-loading-overlay
._loading_overlay_overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

// library classes styles
.navbar-header {
  box-shadow: 0 0 8px $gray-500;
  padding-right: 1.5rem;
}

.header-title {
  margin-left: 1.5rem;
}

.form-control {
  height: 38px;
}

.form-control:disabled {
  background-color: $gray-310;
}

.main-content {
  min-height: 100vh;
}

.table th,
.table td {
  vertical-align: inherit;
}

.modal-content {
  box-shadow: 0 0 8px $gray-800;
}

.modal-header {
  border-bottom: 1px solid $gray-400;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.nav-tabs .nav-link.has-error {
  color: $danger;

  &:hover {
    border-bottom: none;
  }
}

.nav-tabs .nav-link.has-error.active {
  border-color: $danger $danger white;

  &:hover {
    border-bottom: 1px solid transparent;
  }
}

.modal-content {
  max-height: 90vh;

  .modal-body {
    overflow-y: scroll;
    overflow-x: auto;
  }
}

.card {
  box-shadow: 0 0 8px $gray-400;
  border-radius: 0;
}

.input-group-field {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group.prefix input {
  border-left: none !important;
}

.input-group-text.prefix {
  border-right: none !important;
  background: none;

  &.disabled {
    background-color: $gray-310;
  }
}

.input-group.suffix input {
  border-right: none !important;
}

.input-group-text.suffix {
  border-left: none !important;
  background: none;

  &.disabled {
    background-color: $gray-310;
  }
}

#sidebar-menu ul li .badge {
  margin-top: 1px;
  padding-top: 0.35em;
}

.badge-warning {
  color: $gray-800;
  font-weight: bold;
  background-color: $warning;
}

// html components styles
textarea {
  min-height: 100px;
}

// other custom styles
.sidebar-group-title {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;

  span {
    display: inline-block;
    padding-top: 3px;
  }
}

.active-sidebar-link {
  background-color: $gray-900;
}

#sidebar-profile-role {
  color: $warning;
}

.profile-dropdown-menu {
  background-color: $gray-800;
  padding: 0;
}

.profile-dropdown-item {
  padding: 0;

  &:hover {
    animation: profile-dropdown-item-hover-animation 0.15s forwards;
  }

  &:active,
  &:focus {
    background-color: $gray-700;
  }
}

@keyframes profile-dropdown-item-hover-animation {
  from {
    background-color: $gray-800;
  }
  to {
    background-color: $gray-700;
  }
}

.text-button {
  background: none;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.title,
.sub-title {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1rem;
}

.title {
  font-size: 1rem;
}

.sub-title {
  font-size: 0.875rem;
}

.data-table td,
.data-table th {
  max-width: 300px;
  white-space: nowrap;
  overflow: auto;
  padding: 0;

  > div {
    white-space: nowrap;
    overflow: scroll;
    padding: 12px;
    // hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  // hide scrollbar
  > div::-webkit-scrollbar {
    display: none;
  }
}

.data-table-image {
  max-height: 50px;
  max-width: 100%;
}

.data-table-row-buttons {
  max-width: none !important;
  padding: 12px !important;

  button {
    // btn-sm styles
    padding: 0.25rem 0.5rem !important;
    font-size: 0.71094rem !important;
    line-height: 1.5 !important;
  }

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.table-page-link {
  &:hover {
    color: $gray-800 !important;
  }
}

.table-page-item-active {
  pointer-events: none;

  .table-page-link {
    background-color: $gray-800;
    color: $gray-200;
  }
}

.custom-link {
  text-decoration: underline !important;
  color: $gray-700;

  &:hover {
    color: $gray-900;
  }
}

.table-filter-col {
  margin-right: 2rem !important;
}

.table-filter-field-col {
  min-width: 174px;
}

.table-filter-field {
  border: none;
  background-color: $gray-310;
}

.image-field-preview {
  position: relative;
  border: 1px solid $gray-400;
  width: fit-content;
  text-align: center;

  button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50% !important;
  }
}

.image-field-preview-img {
  a > img {
    max-height: 200px;
    max-width: 100%;
  }
}

.image-field-preview-img-sm {
  height: 169px;
  width: 169px;

  a > img {
    max-height: 100%;
    max-width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.watermark-content {
  background-color: $gray-310;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  > div {
    background-color: white;
    padding: 10px;
  }
}
.watermark-canvas {
  display: flex;
  justify-content: center;
}

.watermark-image-wrap {
  display: flex;
  gap: 20px;
  img {
    cursor: pointer;
  }
}

.watermark-button-group {
  display: flex;
  gap: 10px;
}

.resource-field-drag-area,
.resource-field-drag-area-sm {
  height: 162.5px;
  color: $gray-600;
  border: 1px solid $gray-400;
  border-bottom: none;

  p {
    margin-top: 60px;
  }
}

.resource-field-drag-area-sm {
  height: 131.5px;

  p {
    margin-top: 45px;
  }
}

.resource-field-upload-button {
  width: 200px;
}

.resource-field-upload-button-sm {
  width: 169px;
}

.tag {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  border: 1px solid $gray-800;
  padding: 2.5px 5px;
}

.field-wrapper.has-error input,
.field-wrapper.has-error textarea {
  border: 1px solid $danger;
}

.image-field.field-wrapper.has-error .resource-field-uploader,
.file-field.field-wrapper.has-error .resource-field-uploader {
  border: 1px solid $danger;
  width: fit-content;
}

.ck-editor-field.field-wrapper.has-error {
  border: 1px solid $danger;
}

.search-field.field-wrapper.has-error button {
  border: 1px solid $danger;
  color: $danger;

  &:hover {
    background-color: $danger;
    color: white;
  }
}

.canvas-field.field-wrapper.has-error .sigCanvas {
  border: 1px solid $danger;
}

.canvas-field-placeholder {
  color: $gray-500;
  text-transform: uppercase;
  position: absolute;
  top: 125px;
  cursor: url("../images/pen-solid-sm.svg"), auto;
}

.vertical-align-image-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.flag-button {
  vertical-align: middle;
  height: 20px;
}

.flag-button.text-secondary {
  color: $gray-500 !important;
}

.character-count {
  color: $gray-500;
}

// CustomCheckbox styling
// from w3schools https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: inherit;
  white-space: normal;
}

.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox-checkmark {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  border: 1.5px solid $gray-800;
}

.custom-checkbox-container input:checked ~ .custom-checkbox-checkmark {
  background-color: $gray-800;
}

.custom-checkbox-container input:disabled ~ .custom-checkbox-checkmark {
  cursor: default;
  background-color: $gray-320;
  border-color: $gray-400;
}

.custom-checkbox-container input:checked:disabled ~ .custom-checkbox-checkmark {
  cursor: default;
  background-color: $gray-500;
  border-color: $gray-500;
}

.custom-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container input:checked ~ .custom-checkbox-checkmark:after {
  display: block;
}

.custom-checkbox-container .custom-checkbox-checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// https://stackoverflow.com/a/4407335
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttons-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end; // default right-aligned; if want to align left, pls add justify-content-start class
  width: 100%;
  margin-top: -0.25rem;

  > * {
    max-width: fit-content;
    flex: 1 1 auto;
    margin: 0.25rem 0.35rem;
  }
}

.buttons-row.justify-content-start {
  margin-left: -0.35rem;
}

// responsive styling
@media only screen and (max-width: 768px) {
  .table-filter-col {
    margin-right: 0 !important;
    margin-bottom: 0.5rem;
  }

  .table-filter-field-col {
    min-width: 70vw;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-header {
    padding-right: 0.5rem;
  }

  .header-title {
    font-size: 1rem;
    margin-left: 1rem;
  }

  .container-fluid {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .image-field-preview-img,
  .image-field-preview-img-sm {
    height: 158px;
    width: 158px;
  }

  .resource-field-drag-area,
  .resource-field-drag-area-sm {
    height: 120.5px;
    p {
      margin-top: 42.5px;
    }
  }

  .resource-field-upload-button,
  .resource-field-upload-button-sm {
    width: 158px;
  }

  .table-filter-field-col {
    min-width: 90vw;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 574px;
  }
  .modal-dialog.modal-sm {
    max-width: 300px;
  }
  .modal-dialog.modal-lg {
    max-width: 800px;
  }
  .modal-dialog.modal-xl {
    max-width: 1140px;
  }
}

.dataTables {
  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bg-pattern {
  height: 100vh;
  width: 100vw;
  background: url("../images/bg-pattern@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sky {
  background-color: #00497e;
}

.text_sky {
  color: #00497e;
}

.login_btn {
  padding: 0.875rem 3.125rem;
  font-size: 0.875rem;
}

.right {
  right: 0;
}

.remove_hover {
  &:hover {
    color: lighten(#00497e, 5%);
  }
}

.zinc_500 {
  color: #827d83;
}

.bold {
  font-weight: 700;
}

.form-control {
  &:focus {
    border-color: #00497e;
    border-width: 1.5px;
  }
}

.login_focused {
  & label {
    color: #00497e;
  }
}
